import Link from "next/link";
import { type ComponentProps } from "react";
import { useAccount } from "wagmi";
import { APARTMENTS, DISTRICTS } from "~/constants/web3.contract";
import { useCanManageDistrict } from "~/data/useDistrictCollab";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import useUser from "~/lib/useUser";
import Button from "../ui/Button/Button";
import Svg from "../ui/Svg";

interface Props {
  chainId: string;
  contractAddress: string;
  tokenId: string;
  owner: string;
  autoResize?: boolean;
}

const ManageButton = ({
  chainId,
  contractAddress,
  tokenId,
  owner,
  autoResize = false,
}: Props) => {
  const matches = useMediaQuery("(min-width: 768px)");
  const { address } = useAccount();

  const { user } = useUser();

  const isDistrict = Object.values(DISTRICTS)?.some((contracts) =>
    contracts.includes(contractAddress)
  );
  const isApartment = Object.values(APARTMENTS)?.some((contracts) =>
    contracts.includes(contractAddress)
  );
  const isManageable = isDistrict || isApartment;

  const { canManage } = useCanManageDistrict({
    contractAddress,
    tokenId,
    chainId,
  });

  const owned = owner === address?.toLowerCase() || owner === user?.ethAddress;

  const canManageEntity = isManageable && (owned || canManage);

  if (!canManageEntity) {
    return null;
  }

  const pathTypes = isDistrict ? "districts" : isApartment ? "apartments" : "";

  let buttonSize: ComponentProps<typeof Button>["size"] = "medium";
  if (autoResize) {
    buttonSize = matches ? "medium" : "small";
  }

  return (
    <Link
      href={`/space/${pathTypes}/evm/${chainId}/${contractAddress}/${tokenId}`}
    >
      <Button size={buttonSize}>
        <Svg name="settings" width={18} height={18} fill="#CBCBCB" />
        {!matches && <span>Manage</span>}
      </Button>
    </Link>
  );
};

export default ManageButton;
