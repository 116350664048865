export const CHAIN_ID = {
  MAINNET: "1",
  GOERLI: "5",
  SEPOLIA: "11155111",
} as const;

type ContractList = Record<(typeof CHAIN_ID)[keyof typeof CHAIN_ID], string[]>;

export const CITIXENS: ContractList = {
  "1": ["0xaa7fda738f6c08e4c3b8a178ec09885bc6044861"],
  "5": ["0xaa7fda738f6c08e4c3b8a178ec09885bc6044861"],
  "11155111": ["0xf68dc802bb616b6c5499dccc920a9d35bd40e0b2"],
};

export const OG_CITIXENS: ContractList = {
  "1": ["0xcc87cede8c84deba3fa2e503e0758b1c83c06bb1"],
  "5": ["0xcc87cede8c84deba3fa2e503e0758b1c83c06bb1"],
  "11155111": ["0x29d199f2729d73e801f21fcf88747e50dd1c4d90"],
};

export const APARTMENTS: ContractList = {
  "1": ["0xad7978f13a2b043a0d09539264d5ea9302b26135"],
  "5": ["0xa40e43f2e3a16d778a7c5368ed0e3dd3f0e51cee"],
  "11155111": ["0x033828e18ca59c8c7866534423a1662a723011db"],
};

export const DISTRICTS: ContractList = {
  "1": ["0xdfeca7b7a2a292bf6557ed611ecf1ef5d862db08"],
  "5": ["0xef1fbe58d0720deb1f880a6d63d6003294993ba3"],
  "11155111": ["0xb73f75e64f47a43203f46dcd5eb16becc8919819"],
};
