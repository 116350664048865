import NextImage, { type StaticImageData } from "next/image";
import { useEffect, useState } from "react";
import fallbackImage from "~/../public/images/placeholder.png";

const useIsImageValid = (src: string | StaticImageData) => {
  const [valid, setValid] = useState<boolean>(false);
  function checkValidImageSource(
    source: string | StaticImageData
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const image = new Image();

      image.onload = function () {
        // Image loaded successfully
        resolve(true);
      };

      image.onerror = function () {
        // Error loading image
        resolve(false);
      };

      if (typeof source === "string") {
        image.src = source;
      } else {
        image.src = source.src;
      }
    });
  }

  useEffect(() => {
    checkValidImageSource(src)
      .then((isValid) => {
        setValid(isValid);
      })
      .catch(() => {
        setValid(false);
      });
  }, [src]);

  return [valid];
};

const ImageWithFallback = ({
  fallback = fallbackImage,
  alt,
  src,
  ...props
}: {
  fallback?: StaticImageData | string;
  alt: string;
  src: string;
  [key: string]: unknown;
}) => {
  const [srcValid] = useIsImageValid(src);
  const [fallbackValid] = useIsImageValid(fallback);
  let imageSrc: StaticImageData | string = fallbackImage;
  if (srcValid) {
    imageSrc = src;
  } else if (fallbackValid) {
    imageSrc = fallback;
  }

  return (
    <NextImage
      alt={alt}
      placeholder="blur"
      blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAKAAoDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAgK/8QAJBAAAgIBAwQCAwAAAAAAAAAAAQIDBAUGExQABxEhEhUxYXH/xAAYAQADAQEAAAAAAAAAAAAAAAADBwgEBf/EACcRAAICAQMEAgEFAAAAAAAAAAECAwQFBhETAAcSFBUhcQgWIzFB/9oADAMBAAIRAxEAPwDEdoTtj2/1NpDH6wyuqPqhgxdGq8OWdjcbGXbWQkL2yBPifssHLRpY/hU8tyLscjRDlGWrEr9Ras1Jic1ZwlPEe4cgYPh7wCjhFuvFWTxh347nqZBLFizzzU+KB1D/AMISZ7u7Ofp97KdwO2OF7oak7hft1dIrlx3H0uzyuco2nsrkM3OZcmVW5poZ7SFnD4nC/E4zUnu5WCd6w+Sexjq84t8SzFQQpY/EH8hfPoH2fYH7P9PTPG+w3+zsN/z/AL1CkhQu5jBVC7FFP2VQk+IJ3O5A2B+z+T/fSYrNiKrbrxzzR17XH5MCSukNjZkLw78SsEl2nJeLcVttiWTwT56E8UTywyvHG0kXJxSMis8XIoV+NyCyeagK/iR5AbHcdb6t67BjspSguWoad/0veqRWJY6131Z2lq+3AjrFZ9aVjLBzI/DIS8fixJ6L0brm9f/Z"
      src={imageSrc}
      {...props}
    />
  );
};

export default ImageWithFallback;
