import { Fragment, useEffect, useMemo, type FC } from "react";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { UserIcon } from "@heroicons/react/24/solid";

import Image from "next/image";
import Button from "../ui/Button/Button";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { useHasNft } from "~/data/useNFTdata";
import { env } from "~/env.mjs";
import useUser from "~/lib/useUser";
import { CATEGORY } from "../inventory/EvmInventory";
import Notification from "../ui/Notification/Notification";

interface Props {
  children: React.ReactNode;
  publicAccess?: boolean;
}

const Footer = dynamic(() => import("./Footer"));

const WrongChainBanner = dynamic(() => import("../ui/Banner/WrongChain"), {
  ssr: false,
});

const Layout: FC<Props> = (props: Props) => {
  const hasDistrict = useHasNft(CATEGORY.DISTRICT);
  const hasApartment = useHasNft(CATEGORY.APARTMENT);

  const navigation = [
    {
      name: "ROADMAP",
      href: "https://www.rfoxvalt.com/roadmap",
      type: "external",
      experimental: false,
      onlyUser: false,
    },
    {
      name: "NEWS",
      href: "https://rfoxvalt.com/news",
      type: "external",
      experimental: false,
      onlyUser: false,
    },
    {
      name: "FEEDBACK",
      href: "https://rfox.canny.io/rfoxvalt",
      type: "external",
      experimental: false,
      onlyUser: false,
    },
    {
      name: "CREATE AI ASSET",
      href: hasDistrict || hasApartment ? "/asset/generate" : "/asset",
      type: "internal",
      experimental: true,
      onlyUser: true,
      disabled: false,
    },
    {
      name: "EXPLORE AI NPCs",
      href: "/explore-npcs",
      type: "internal",
      experimental: false,
      onlyUser: false,
    },
  ];

  const router = useRouter();
  const { user } = useUser();

  useEffect(() => {
    if (!props.publicAccess && user && !user.isLoggedIn) {
      void router.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, user?.isLoggedIn]);

  return (
    <div className="flex h-screen flex-col">
      <Disclosure as="nav" className="bg-black">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between lg:justify-between">
                <div className="-mr-2 flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex items-center">
                  <Link
                    href="https://rfoxvalt.com"
                    className="h-auto w-auto flex-shrink-0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src="/images/rfox-valt.svg"
                      alt="rfox-valt"
                      width={143}
                      height={36}
                    />
                  </Link>
                  <div className="hidden lg:block">
                    <div className="ml-10 flex items-baseline gap-8">
                      {navigation.map((item) => (
                        <>
                          {!item.experimental &&
                            (item.onlyUser ? user?.isLoggedIn : true) &&
                            (item.disabled ? (
                              <span
                                className={
                                  "my-auto rounded-md font-serif text-base font-normal uppercase leading-4 text-light-100 opacity-50"
                                }
                              >
                                {item.name}
                              </span>
                            ) : (
                              <Link
                                key={item.name}
                                href={item.href}
                                className={
                                  "my-auto rounded-md font-serif text-base font-normal leading-4 text-light-100 hover:text-primary-100"
                                }
                                target={
                                  item.type === "external" ? "_blank" : "_self"
                                }
                                rel={
                                  item.type === "external"
                                    ? "noopener noreferrer"
                                    : ""
                                }
                              >
                                {item.name}
                              </Link>
                            ))}

                          {item.experimental &&
                            env.NEXT_PUBLIC_EXPERIMENTAL &&
                            (item.onlyUser ? user?.isLoggedIn : true) &&
                            (item.disabled ? (
                              <span
                                className={
                                  "my-auto rounded-md font-serif text-base font-normal uppercase leading-4 text-light-100 opacity-50"
                                }
                              >
                                {item.name}
                              </span>
                            ) : (
                              <Link
                                key={item.name}
                                href={item.href}
                                className={
                                  "my-auto rounded-md font-serif text-base font-normal uppercase leading-4 text-light-100 hover:text-primary-100"
                                }
                                target={
                                  item.type === "external" ? "_blank" : "_self"
                                }
                                rel={
                                  item.type === "external"
                                    ? "noopener noreferrer"
                                    : ""
                                }
                              >
                                {item.name}
                              </Link>
                            ))}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block">
                  <div className="flex items-center">
                    <div className="flex gap-4">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://rfoxvalt.com/download"
                      >
                        <button className="inline-flex cursor-pointer items-center justify-center rounded-3xl border border-primary-100 bg-primary-100 px-3 py-2 font-serif text-base font-medium uppercase text-white shadow-sm hover:ring-2 hover:ring-primary-100 disabled:opacity-50">
                          <div className="flex flex-row items-center gap-2 px-4">
                            <ArrowDownCircleIcon className="h-6 w-6 stroke-2" />
                            INSTALL APP
                          </div>
                        </button>
                      </Link>
                      {user?.isLoggedIn && (
                        <Link href="/">
                          <Button
                            intent={user?.isLoggedIn ? "secondary" : "primary"}
                          >
                            <UserIcon
                              className="mr-3 h-5 w-5"
                              aria-hidden="true"
                            />
                            {user?.isLoggedIn ? "My Account" : "Sign in"}
                          </Button>
                        </Link>
                      )}
                    </div>

                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))} */}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>

                <div className="-mr-2 flex gap-2 lg:hidden">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://rfoxvalt.com/download"
                  >
                    <button className="inline-flex cursor-pointer items-center justify-center rounded-full border border-primary-100 bg-primary-100 px-2 py-2 font-serif text-base font-medium uppercase text-white shadow-sm hover:ring-2 hover:ring-primary-100 disabled:opacity-50">
                      <div className="flex flex-row items-center gap-2">
                        <ArrowDownCircleIcon className="h-6 w-6 stroke-2" />
                      </div>
                    </button>
                  </Link>
                  <Link href="/">
                    <Button intent="secondary">
                      <UserIcon className="h-5 w-5" aria-hidden="true" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <>
                    {!item.experimental &&
                      (item.onlyUser ? user?.isLoggedIn : true) && (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={
                            "block  rounded-md px-3 py-2 text-base font-medium text-light-100 hover:text-primary-100"
                          }
                        >
                          {item.name}
                        </Disclosure.Button>
                      )}

                    {item.experimental &&
                      env.NEXT_PUBLIC_EXPERIMENTAL &&
                      (item.onlyUser ? user?.isLoggedIn : true) && (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={
                            "block  rounded-md px-3 py-2 text-base font-medium text-light-100 hover:text-primary-100"
                          }
                        >
                          {item.name}
                        </Disclosure.Button>
                      )}
                  </>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <WrongChainBanner />

      <main className="flex-1 ">
        {props.children}

        <Footer />
      </main>
      <Notification />
    </div>
  );
};

export default Layout;
