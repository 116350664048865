import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useContext } from "react";
import { NotificationContext } from "~/context/NotificationContext";
import { classNames } from "~/utils";

const Notification: React.FC = () => {
  const { notification, hideNotification, isOpen } =
    useContext(NotificationContext);

  if (!notification) {
    return null;
  }

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-x-0 inset-y-12 z-20 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ",
              notification.type === "success"
                ? "bg-success-50"
                : notification.type === "error"
                ? "bg-error-50"
                : notification.type === "warning"
                ? "bg-warning-50"
                : "bg-arkamoto-100"
            )}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {notification.type === "success" ? (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  ) : notification.type === "error" ? (
                    <XCircleIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  ) : notification.type === "warning" ? (
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-yellow-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <InformationCircleIcon
                      className="text-arkamoto-400 h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-bold uppercase text-dark-100">
                    {notification.type}
                  </p>
                  <p className="mt-1 text-sm text-dark-200">
                    {notification.message}
                  </p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    className="inline-flex rounded-md  text-dark-250 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:ring-offset-2"
                    onClick={hideNotification}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Notification;
