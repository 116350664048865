import { Fragment, useEffect, useRef, useState, type SVGProps } from "react";

import { useInfiniteQuery } from "@tanstack/react-query";

import axios from "axios";
import { useAtom } from "jotai";
import Link from "next/link";
import { useAccount, useChainId } from "wagmi";
import { useUserNftFilter, useUserNfts } from "~/data/useNFTdata";
import { env } from "~/env.mjs";
import { ethWhitelist } from "~/lib/atom";
import useUser from "~/lib/useUser";
import { classNames, ipfsHttpGateway, sanitizedImageURL } from "~/utils";
import ImageWithFallback from "../ui/Image/Image";
import EmptyTemplate from "./Empty";
import ManageButton from "./ManageButton";

export interface NftsResponse {
  hasNext: boolean;
  cursor: string;
  nfts: NFT[];
}

export enum CATEGORY {
  APARTMENT = "APARTMENT",
  AVATAR = "AVATAR",
  AVATAR_OG = "AVATAR_OG",
  APARTMENTS = "APARTMENTS",
  DISTRICT = "DISTRICT",
  ALL = "ALL",
}

export interface NFT {
  chain: string;
  contractType: string;
  tokenAddress: string;
  tokenId: string;
  tokenUri: string;
  metadata: {
    image: string;
    animation_url: string;
    attributes: {
      [key: string]: string | number | boolean;
    };
    description: string;
    name: string;
  };
  name: string;
  symbol: string;
  amount: number;
  blockNumberMinted: string;
  blockNumber: string;
  ownerOf: string;
  tokenHash: string;
  lastMetadataSync: string;
  lastTokenUriSync: string;
}

const InventoryItem: React.FC<
  NFT & {
    isLast: boolean;
    hitCurrentLastItem: () => void;
    actionsComponent?: (props: NFT) => React.ReactNode;
    isManageable?: boolean;
  }
> = ({ ...props }) => {
  const {
    metadata,
    name,
    isLast,
    hitCurrentLastItem,
    tokenId,
    chain,
    ownerOf,
    tokenAddress,
    actionsComponent,
    isManageable,
  } = props;

  const itemRef = useRef(null);

  useEffect(() => {
    if (!itemRef?.current) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (isLast && entry?.isIntersecting) {
        hitCurrentLastItem();
        observer.unobserve(entry.target);
      }
    });

    observer.observe(itemRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLast]);

  return (
    <div
      className="group relative overflow-clip rounded-lg  bg-dark-200 shadow-sm hover:animate-pulse"
      ref={itemRef}
    >
      <div className="min-h-80 aspect-h-1 aspect-w-1 w-full overflow-hidden">
        <ImageWithFallback
          src={sanitizedImageURL(metadata?.image || "")}
          fallback={ipfsHttpGateway(metadata?.image || "")}
          alt={metadata?.name || name}
          className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          width={400}
          height={400}
        />
      </div>
      <Link
        className="flex"
        href={`/nft/evm/${chain}/${tokenAddress}/${tokenId}`}
      >
        <div
          className={classNames(
            "h-full flex-1 px-2 pb-8 pt-4",
            isManageable
              ? "flex flex-col items-center justify-between space-y-2 text-center md:flex-row md:text-start"
              : ""
          )}
        >
          <div>
            <h3 className="text-md overflow-hidden text-ellipsis whitespace-nowrap text-primary-100">
              {metadata?.name ?? `#${tokenId}`}
            </h3>
            <p className="text-md mb-auto text-gray-500">{name}</p>
          </div>

          {isManageable && (
            <div>
              <ManageButton
                chainId={chain}
                contractAddress={tokenAddress}
                tokenId={tokenId}
                owner={ownerOf?.toLowerCase() || ""}
                autoResize
              />
            </div>
          )}
        </div>
      </Link>
      {actionsComponent && actionsComponent({ ...props })}
    </div>
  );
};

const InventoryItemSkeleton: React.FC = () => {
  return (
    <div className="group relative animate-pulse opacity-20">
      <div className="min-h-80 bg-dark-00 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md">
        <div className="h-full rounded bg-dark-100"></div>
      </div>
    </div>
  );
};

export interface Props {
  children?: React.ReactNode;
  nfts: NFT[];
}

const EmptyDistrict = () => {
  const items = [
    {
      title: "RFOX VALT Districts",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/rfoxvalt",
    },
  ];

  return EmptyTemplate(
    "You don't currently own a District, but you can purchase one below:",
    items
  );
};

const EmptyApartment = () => {
  const items = [
    {
      title: "RFOX VALT Apartments",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/rfoxvalt-apartments",
    },
  ];

  return EmptyTemplate(
    "You don't currently own an Apartment, but you can purchase one below:",
    items
  );
};

const EmptyOG = () => {
  const items = [
    {
      title: "RFOX VALT OG CitiXens",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/og-citixens",
    },
  ];

  return EmptyTemplate(
    "You don't currently own an OG CitiXen, but you can purchase one below:",
    items
  );
};

const EmptyAvatar = () => {
  const items = [
    {
      title: "RFOX VALT CitiXens",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/citixens",
    },
  ];

  return EmptyTemplate(
    "You don't currently own a CitiXen, but you can purchase one below:",
    items
  );
};

const EmptyAll = () => {
  const items = [
    {
      title: "RFOX VALT Districts",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/rfoxvalt",
    },
    {
      title: "RFOX VALT Apartments",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/rfoxvalt-apartments",
    },
    {
      title: "RFOX VALT OG CitiXens",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/og-citixens",
    },
    {
      title: "RFOX VALT CitiXens",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/citixens",
    },
    {
      title: "RFOX VALT MEGAMOON",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/rfox-valt-megamoon",
    },
    {
      title: "KOGs",
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg viewBox="0 0 67 82" fill="currentColor" {...props}>
          <path d="M0 0V33.4961L16.2168 17.0572L0 0Z" fill="#CD0D0D" />
          <path
            d="M50.7832 17.0572L67 33.4961V0L50.7832 17.0572Z"
            fill="#CD0D0D"
          />
          <path
            d="M2.2627 36.948L32.5316 82L17.9976 21.0071L2.2627 36.948Z"
            fill="#CD0D0D"
          />
          <path
            d="M34.6265 82L64.8859 36.948L49.1604 21.0071L34.6265 82Z"
            fill="#CD0D0D"
          />
          <path
            d="M45.5144 20.3469H33.5555H21.5967L27.577 46.7655L33.5574 73.1747L39.5378 46.7655L45.5144 20.3469Z"
            fill="#CD0D0D"
          />
        </svg>
      ),
      background: "bg-black",
      url: "https://opensea.io/collection/kogs",
    },
  ];

  return EmptyTemplate(
    "You don't own any NFTs, but you can start your collection by purchasing unique NFTs from the RFOX ecosystem below:",
    items
  );
};

const EMPTY_STATE: { [key: string]: React.ReactNode } = {
  [CATEGORY.DISTRICT]: <EmptyDistrict />,
  [CATEGORY.APARTMENT]: <EmptyApartment />,
  [CATEGORY.AVATAR_OG]: <EmptyOG />,
  [CATEGORY.AVATAR]: <EmptyAvatar />,
  [CATEGORY.ALL]: <EmptyAll />,
};

const EvmInventory: React.FC<{
  filter?: CATEGORY;
  mockAddress?: string;
  isManageable?: boolean;
  itemProps?: {
    actionsComponent: (props: NFT) => React.ReactNode;
  };
  whitelist?: string[];
  filterBy?: string[];
}> = ({ ...props }) => {
  const {
    filter,
    mockAddress,
    isManageable = true,
    itemProps,
    filterBy = [],
  } = props;
  const [isDefinitelyConnected, setIsDefinitelyConnected] = useState(false);

  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      setIsDefinitelyConnected(true);
    } else {
      setIsDefinitelyConnected(false);
    }
  }, [isConnected]);

  const chainId = useChainId();

  const { user } = useUser();

  const [whitelist] = useAtom(ethWhitelist);

  const { isFetched: fetchedEvmNfts, data: evmNfts } = useUserNfts({
    limit: 100, // Moralis limit reduced to 100
  });
  const filterNfts = useUserNftFilter(evmNfts ?? []);

  const fetchUserNfts = async ({
    pageParam = "",
    queryKey,
  }: {
    pageParam?: string;
    queryKey: [string, string, number, string, string, boolean];
  }) => {
    const [, , , filterCombined, filterBy, fetchedEvmNfts] = queryKey;

    if (filterCombined === "empty") return Promise.resolve({} as NftsResponse);

    const queryParams: {
      chainId?: string;
      filter?: string;
      cursor?: string;
      whitelist?: string;
      filterBy?: string;
      address?: string;
    } = {};
    queryParams["address"] = mockAddress
      ? mockAddress
      : ((address || user?.ethAddress) as string);
    if (chainId) queryParams["chainId"] = chainId.toString();
    if (filter) queryParams["filter"] = filter;
    if (pageParam) queryParams["cursor"] = pageParam;
    if (filterCombined && filter === CATEGORY.ALL)
      queryParams["whitelist"] = filterCombined;

    if (filter === CATEGORY.ALL && fetchedEvmNfts && evmNfts?.length) {
      const filteredNfts = filterNfts({
        tokenAddresses: filterCombined.split(",").filter((item) => !!item),
        filters: filterBy.split(",").filter((item) => !!item),
        cursor: pageParam,
      });
      return filteredNfts;
    }

    const { data } = await axios.get<NftsResponse>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/web3/nfts`,
      {
        params: queryParams,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery(
    [
      filter || "ALL",
      address || user?.ethAddress || "0x00",
      chainId,
      whitelist,
      filterBy.join(","),
      fetchedEvmNfts,
    ],
    fetchUserNfts,
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasNext && !!lastPage.cursor ? lastPage.cursor : undefined,
      enabled: !!address || !!user?.ethAddress,
    }
  );

  if (!user?.ethAddress && !isDefinitelyConnected) {
    return (
      <div className="pt-8">
        No ETH Wallet connected or verified click{" "}
        <Link href="/" className="underline hover:text-primary-100">
          {" "}
          to continue
        </Link>
      </div>
    );
  }

  if (!data?.pages[0]?.nfts?.length && !isLoading)
    return filterBy.length ? (
      <div className="mt-6">No search result found.</div>
    ) : (
      <div>{EMPTY_STATE[filter || ""]}</div>
    );

  return (
    <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
      {((isLoading || isFetching) && !isFetchingNextPage) ||
      (filterBy.length && !fetchedEvmNfts) ? (
        <>
          {Array.from(Array(10).keys()).map((i) => (
            <InventoryItemSkeleton key={i} />
          ))}
        </>
      ) : (
        <>
          {data?.pages.map((response, i) => (
            <Fragment key={i}>
              {response.nfts.filter(Boolean).map((nft, index) => (
                <InventoryItem
                  {...{
                    ...nft,
                    isLast: index === response.nfts.length - 1,
                    hitCurrentLastItem: () => {
                      if (hasNextPage) {
                        void fetchNextPage();
                      }
                    },
                    isManageable:
                      (filter === CATEGORY.DISTRICT && isManageable) ||
                      (filter === CATEGORY.APARTMENT && isManageable),
                  }}
                  key={`${nft.tokenAddress}-${nft.tokenId}`}
                  {...itemProps}
                />
              ))}
            </Fragment>
          ))}

          {isFetchingNextPage && (
            <>
              {Array.from(Array(10).keys()).map((i) => (
                <InventoryItemSkeleton key={i} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EvmInventory;
