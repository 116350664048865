import Link from "next/link";
import { type SVGProps } from "react";
import { classNames } from "~/utils";

const EmptyTemplate = (
  title: string,
  items: {
    title: string;
    icon: (
      props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
    ) => JSX.Element;
    background: string;
    url: string;
  }[]
) => {
  return (
    <div className="pt-8">
      <p className="mt-1 text-sm text-light-300">{title}</p>
      <ul
        role="list"
        className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
      >
        {items.map((item, itemIdx) => (
          <li key={itemIdx} className="flow-root">
            <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-primary-100 hover:bg-primary-100">
              <div
                className={classNames(
                  item.background,
                  "flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg"
                )}
              >
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div>
                <h3 className="text-sm font-bold text-light-150">
                  <Link
                    href={item.url}
                    className="focus:outline-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.title}
                  </Link>
                </h3>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmptyTemplate;
