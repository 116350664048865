import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";
import { type IDistrict, useDistrictData } from "~/data/useDistrictData";
import { env } from "~/env.mjs";
import useUser from "~/lib/useUser";
import { type IIdentifier } from "~/type";
import { getProductTypeViaUrl } from "~/utils";
import { useChainId } from "wagmi";
import { type ICollab } from "~/data/useDistrictCollab";

export enum Permission {
  READ = "READ",
  MANAGE = "MANAGE",
}

export enum AccessMode {
  BASIC = "BASIC",
  ADVANCE = "ADVANCE",
}

export enum AccessType {
  ONLY_OWNER = "ONLY_OWNER",
  EVERYONE = "EVERYONE",
  WHITELIST_ETH = "WHITELIST_ETH",
  WHITELIST_WAX = "WHITELIST_WAX",
  WHITELIST_ALL = "WHITELIST_ALL",
  WHITELIST_RFOXID = "WHITELIST_RFOXID",
}
export interface IAccess {
  id: string;
  tokenId: string;
  contractAddress: string;
  address: string;
  permission: Permission;
  type: "ETH" | "WAX";
  createdAt: string;
  updatedAt: string;
}

export const useDistrictAccessData = ({
  contractAddress,
  tokenId,
  permission,
}: IIdentifier & { permission: Permission }) => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const { pathname } = useRouter();
  const productType = getProductTypeViaUrl(pathname);

  const fetchAccesses = async () => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/access/${contractAddress}/${tokenId}/${permission}`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/access/apartment/${tokenId}/${permission}`;
    const { data } = await axios.get<Array<IAccess>>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [
    productType,
    "access",
    contractAddress,
    tokenId,
    permission,
  ];

  const queryObj = useQuery(queryKey, fetchAccesses, {
    enabled: !!contractAddress && !!tokenId,
  });
  return {
    ...queryObj,
    data: queryObj.data || [],
  };
};

export const useAddDistrictAccess = ({
  contractAddress,
  tokenId,
  permission,
}: IIdentifier & { permission: Permission }) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const { pathname } = useRouter();
  const productType = getProductTypeViaUrl(pathname);

  const addAccess = async (data: Partial<IAccess>) => {
    const payload = {
      ...data,
      permission,
      tokenId,
      contractAddress,
    };
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/access`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/access/apartment`;
    await axios.post<IDistrict>(url, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationFn: addAccess,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        "access",
        contractAddress,
        tokenId,
      ]);
    },
  });
};

export const useUpdateDistrictAccessMode = () => {
  const queryClient = useQueryClient();
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);

  const changeAccess = async (accessMode: AccessMode) => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/districts/${contractAddress}/${tokenId}/accessMode?accessMode=${accessMode}`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/apartment/${tokenId}/accessMode?accessMode=${accessMode}`;
    await axios.post<IDistrict>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn: changeAccess,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        contractAddress,
        tokenId,
      ]);
    },
  });
};

export const useUpdateDistrictAccessType = () => {
  const queryClient = useQueryClient();
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);

  const changeAccess = async (accessType: AccessType) => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/districts/${contractAddress}/${tokenId}/accessType?accessType=${accessType}`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/apartment/${tokenId}/accessType?accessType=${accessType}`;
    await axios.post<IDistrict>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn: changeAccess,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        contractAddress,
        tokenId,
      ]);
    },
  });
};

export const useRemoveDistrictAccess = ({
  contractAddress,
  tokenId,
}: IIdentifier) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const { pathname } = useRouter();
  const productType = getProductTypeViaUrl(pathname);

  const removeAccess = async (address: string) => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/access/${contractAddress}/${tokenId}/${address}`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/access/apartment/${tokenId}/${address}`;
    await axios.delete<IAccess>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationFn: removeAccess,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        "access",
        contractAddress,
        tokenId,
      ]);
    },
  });
};

export const useAddRfoxIdDistrictAccess = () => {
  const queryClient = useQueryClient();
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);
  const propertyType = productType === "districts" ? "DISTRICT" : "APARTMENT";
  const chainId = useChainId();
  const testnet = chainId !== 1;
  const { data } = useDistrictData();
  const shopId = data?.shopId || tokenId;

  const mutationFn = async (rfoxId: string) => {
    const url = `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/rfoxId-Access/rfoxId/${rfoxId}`;
    await axios.post(
      url,
      {
        contractAddress,
        tokenId,
        rfoxId,
        propertyType,
      },
      {
        params: {
          testnet,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        "access",
        contractAddress,
        shopId,
        "rfoxIds",
      ]);
    },
  });
};

export const useRemoveRfoxIdDistrictAccess = () => {
  const queryClient = useQueryClient();
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);
  const propertyType = productType === "districts" ? "DISTRICT" : "APARTMENT";
  const { data } = useDistrictData();
  const shopId = data?.shopId || tokenId;
  const chainId = useChainId();
  const isTestnet = chainId !== 1;

  const mutationFn = async (rfoxId: string) => {
    const url = `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/rfoxId-Access/rfoxId/${rfoxId}`;
    await axios.delete(url, {
      data: {
        contractAddress,
        tokenId,
        rfoxId,
        propertyType,
        testnet: isTestnet,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        "access",
        contractAddress,
        shopId,
        "rfoxIds",
      ]);
    },
  });
};

export const useDistrictAccessRfoxIds = () => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const { pathname, query } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const productType = getProductTypeViaUrl(pathname);
  const { data } = useDistrictData();
  const shopId = data?.shopId || tokenId;
  const chainId = useChainId();
  const testnet = chainId !== 1;

  const queryFn = async () => {
    if (!shopId) return;
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/rfoxId-Access/district/${shopId}/rfoxId`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/rfoxId-Access/apartment/${shopId}/rfoxId`;
    const { data } = await axios.get<ICollab[]>(url, {
      params: {
        testnet,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [productType, "access", contractAddress, shopId, "rfoxIds"];

  const queryObj = useQuery(queryKey, queryFn, {
    enabled: !!accessToken && !!contractAddress && !!tokenId && !!shopId,
  });
  return {
    ...queryObj,
    data: queryObj.data || [],
  };
};
