import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";
import { useAccount, useChainId } from "wagmi";
import { type NFT } from "~/components/inventory/EvmInventory";
import { APARTMENTS, DISTRICTS } from "~/constants/web3.contract";
import { Permission, useDistrictAccessRfoxIds } from "~/data/useDistrictAccess";
import { useNFTData } from "~/data/useNFTdata";
import { env } from "~/env.mjs";
import useUser from "~/lib/useUser";
import { type IChainIdentifier, type IIdentifier } from "~/type";
import { getProductTypeViaUrl } from "~/utils";

export const useAddDistrictUserCollab = ({
  contractAddress,
  tokenId,
}: IIdentifier) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const chainId = useChainId();
  const isTestnet = chainId !== 1;
  const { pathname } = useRouter();
  const productType = getProductTypeViaUrl(pathname);

  const addAccess = async (playfabId: string) => {
    const payload = {
      playfabId,
    };
    const url =
      productType === "districts"
        ? `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/districts/${contractAddress}/${tokenId}/MANAGE?testnet=${isTestnet.toString()}`
        : `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/apartment/${tokenId}/MANAGE?testnet=${isTestnet.toString()}`;
    await axios.post<{ id: string }>(url, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationFn: addAccess,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        "collab",
        contractAddress,
        tokenId,
        "MANAGE",
      ]);
    },
  });
};

export interface ICollab {
  id: string;
  tokenId: string;
  contractAddress: string;
  user: {
    playfabId: string;
    email: string;
  };
  userId: string;
  permission?: Permission;
  createdAt: string;
  updatedAt: string;
}

export const useDistrictCollabData = ({
  contractAddress,
  tokenId,
}: IIdentifier) => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const { pathname } = useRouter();
  const productType = getProductTypeViaUrl(pathname);
  const chainId = useChainId();
  const isTestnet = chainId !== 1;

  const fetchAccesses = async () => {
    const url =
      productType === "districts"
        ? `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/districts/${contractAddress}/${tokenId}/MANAGE?testnet=${isTestnet.toString()}`
        : `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/apartment/${tokenId}/MANAGE?testnet=${isTestnet.toString()}`;

    const { data } = await axios.get<Array<ICollab>>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [productType, "collab", contractAddress, tokenId, "MANAGE"];

  const queryObj = useQuery(queryKey, fetchAccesses, {
    enabled:
      !!contractAddress && !!tokenId && !!user?.accessToken && !!user?.id,
  });
  return {
    ...queryObj,
    data: queryObj.data || [],
  };
};

export const useRemoveDistrictCollab = ({
  contractAddress,
  tokenId,
}: IIdentifier) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const { pathname } = useRouter();
  const chainId = useChainId();
  const isTestnet = chainId !== 1;
  const productType = getProductTypeViaUrl(pathname);

  const removeAccess = async (userId: string) => {
    const url =
      productType === "districts"
        ? `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/districts/${contractAddress}/${tokenId}/MANAGE/${userId}?testnet=${isTestnet.toString()}`
        : `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/apartment/${tokenId}/MANAGE/${userId}?testnet=${isTestnet.toString()}`;

    await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationFn: removeAccess,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        "collab",
        contractAddress,
        tokenId,
        "MANAGE",
      ]);
    },
  });
};

export const useUserPermissionDistrict = ({
  contractAddress,
  tokenId,
}: IIdentifier) => {
  const permission = Permission.MANAGE;
  const { user } = useUser();
  const userId = user?.id || "";
  const accessToken = user?.accessToken || "";
  const chainId = useChainId();
  const isTestnet = chainId !== 1;

  let productType = "";
  const isDistrict = Object.values(DISTRICTS)?.some((contracts) =>
    contracts.includes(contractAddress)
  );
  if (isDistrict) productType = "districts";
  const isApartment = Object.values(APARTMENTS)?.some((contracts) =>
    contracts.includes(contractAddress)
  );
  if (isApartment) productType = "apartment";

  const fetchAccess = async () => {
    const url =
      productType === "districts"
        ? `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/districts/${contractAddress}/${tokenId}/MANAGE/${userId}?testnet=${isTestnet.toString()}`
        : `${
            env.NEXT_PUBLIC_PORTAL_API_URL
          }/api/v1/apartment/${tokenId}/MANAGE/${userId}?testnet=${isTestnet.toString()}`;

    const { data } = await axios.get<ICollab>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [
    productType,
    "collab",
    contractAddress,
    tokenId,
    permission,
    "MANAGE",
    userId,
  ];

  const queryObj = useQuery(queryKey, fetchAccess, {
    enabled: !!contractAddress && !!tokenId && !!userId,
  });
  return {
    ...queryObj,
    canManage: !!queryObj?.data,
    data: queryObj.data,
  };
};

export const useCanManageDistrict = ({
  contractAddress,
  tokenId,
  chainId,
}: IChainIdentifier) => {
  const { canManage, isLoading: isLoadingPermission } =
    useUserPermissionDistrict({
      contractAddress,
      tokenId,
    });
  const { address, isConnecting } = useAccount();
  const { user, isLoading: isLoadingUser } = useUser();
  const { data, isLoading: isLoadingNFTData } = useNFTData({
    chainId,
    contractAddress,
    tokenId,
  });
  const { data: accessDataRfoxIds } = useDistrictAccessRfoxIds();
  const canAccessViaRfoxId = accessDataRfoxIds?.some(
    (item) => item.user?.playfabId === user?.playfabId
  );
  const owned =
    !!data?.ownerOf &&
    (data.ownerOf === address?.toLowerCase() ||
      data.ownerOf === user?.ethAddress?.toLowerCase());
  const isDistrict = Object.values(DISTRICTS)?.some((contracts) =>
    contracts.includes(contractAddress)
  );
  const isApartment = Object.values(APARTMENTS)?.some((contracts) =>
    contracts.includes(contractAddress)
  );
  const isManageable = isDistrict || isApartment;
  return {
    canManage: isManageable && (owned || canManage || canAccessViaRfoxId),
    isLoading:
      isLoadingUser || isConnecting || isLoadingPermission || isLoadingNFTData,
  };
};

export const useDistrictCollaborations = () => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const chainId = useChainId();
  const chainIdHex = "0x" + chainId.toString(16);

  const queryFn = async () => {
    const { data } = await axios.get<Array<NFT>>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/user/districts/MANAGE`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  };

  const queryKey = ["districts", "collab", "MANAGE"];

  const queryObj = useQuery(queryKey, queryFn, {
    enabled: !!user?.accessToken,
  });
  return {
    ...queryObj,
    data: queryObj.data?.filter((item) => item.chain === chainIdHex) || [],
  };
};

export const useApartmentCollaborations = () => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const chainId = useChainId();
  const chainIdHex = "0x" + chainId.toString(16);

  const queryFn = async () => {
    const { data } = await axios.get<Array<NFT>>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/user/apartments/MANAGE`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  };

  const queryKey = ["apartment", "collab", "MANAGE"];

  const queryObj = useQuery(queryKey, queryFn, {
    enabled: !!user?.accessToken,
  });
  return {
    ...queryObj,
    data: queryObj.data?.filter((item) => item.chain === chainIdHex) || [],
  };
};
