import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentProps } from "react";
import { classNames } from "~/utils";

const ButtonStyles = cva(
  "inline-flex items-center rounded-md border font-medium text-white shadow-sm font-serif justify-center",
  {
    variants: {
      intent: {
        primary:
          "bg-primary-100 border-primary-100 disabled:opacity-50 hover:ring-2 hover:ring-primary-100",
        secondary:
          "bg-dark-400 border-dark-400 disabled:opacity-50  hover:ring-2 hover:ring-dark-400",
      },
      width: {
        full: "w-full px-3 py-2",
        auto: "px-3 py-2",
        min: "w-min px-0 py-0",
        extra: "px-3 py-2 md:px-8 md:py-2",
        minLength: "min-w-[5rem] px-3 py-2",
      },
      uppercase: {
        true: "uppercase",
      },
      outline: {
        true: "bg-transparent border",
      },
      size: {
        extraSmall: "text-xs",
        small: "text-sm",
        medium: "text-base",
        large: "text-lg",
        extraLarge: "text-xl",
      },
    },
    defaultVariants: {
      intent: "primary",
      width: "auto",
      uppercase: true,
      outline: false,
      size: "medium",
    },
  }
);

export interface Props
  extends ComponentProps<"button">,
    VariantProps<typeof ButtonStyles> {
  children: React.ReactNode;
}

const Button = ({
  intent,
  width,
  uppercase,
  outline,
  size,
  ...props
}: Props) => {
  return (
    <button
      type="button"
      className={classNames(
        ButtonStyles({ intent, width, uppercase, outline, size }),
        props.disabled ? "cursor-not-allowed" : "cursor-pointer"
      )}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
