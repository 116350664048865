import { classNames } from "~/utils";

export interface Props {
  children: React.ReactNode;
  background?: string;
  backgroundStyle?: string;
}

export const Container: React.FC<Props> = ({ ...props }) => {
  return (
    <div className="container mx-auto mb-8 min-h-screen max-w-7xl px-6 py-6 lg:px-8">
      {props.children}
    </div>
  );
};

export const FullWidthContainer: React.FC<Props> = ({ ...props }) => {
  const { background } = props;

  return (
    <div
      className={classNames(
        "container mx-0 mb-8 min-h-screen max-w-full px-2 py-2 shadow lg:px-12",
        background
          ? `${background} bg-origin-center bg-contain bg-no-repeat`
          : ""
      )}
    >
      {props.children}
    </div>
  );
};

export const MaxWidthContainer: React.FC<Props> = ({ ...props }) => {
  const { background, backgroundStyle } = props;

  return (
    <div
      className={classNames(
        "container mx-0 max-w-full px-2 py-2 pb-8 pt-2 shadow lg:px-12",
        background ? ` ${background}` : "",
        backgroundStyle ? ` ${backgroundStyle}` : ""
      )}
    >
      {props.children}
    </div>
  );
};
