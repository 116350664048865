import {
  type EvmAddress,
  type GetNFTMetadataResponse,
} from "@moralisweb3/common-evm-utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useChainId } from "wagmi";
import {
  type CATEGORY,
  type NFT,
  type NftsResponse,
} from "~/components/inventory/EvmInventory";
import { env } from "~/env.mjs";
import useUser from "~/lib/useUser";
import { type IChainIdentifier } from "~/type";

export interface NormalizedMetadata {
  name: string;
  description: string;
  animationUrl: null;
  externalLink: null;
  image: string;
  attributes: Array<{
    traitType: string;
    value: string;
    displayType: null;
    maxValue: null;
    traitCount: number;
    order: null;
  }>;
}

export interface NftResponse extends Omit<GetNFTMetadataResponse, "ownerOf"> {
  normalizedMetadata?: NormalizedMetadata;
  minterAddress: EvmAddress | undefined;
  ownerOf: string;
  metadata: {
    image: string;
    animation_url: string;
    name: string;
    attributes: Array<{
      trait_type: string;
      value: string;
    }>;
  };
}

export const useNFTData = (params?: IChainIdentifier) => {
  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const { query } = useRouter();
  const contractAddress =
    params?.contractAddress || (query.contractAddress as string);
  const tokenId = params?.tokenId || (query.tokenId as string);
  const chainId = params?.chainId || (query.chainId as string);

  const fetchEthNft = async () => {
    const { data } = await axios.get<NftResponse>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/web3/nft?chainId=${
        chainId || ""
      }&contractAddress=${contractAddress || ""}&tokenId=${tokenId || ""}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  };

  const queryKey = [
    `${chainId || "1"}-${contractAddress || "2"}- ${tokenId || "3"}`,
  ];

  const queryObj = useQuery(queryKey, fetchEthNft, {
    enabled: !!chainId && !!contractAddress && !!tokenId,
  });
  return queryObj;
};

export const useHasNft = (category: CATEGORY) => {
  const { user } = useUser();
  const chainId = useChainId();

  const fetchUserNfts = async () => {
    const { data } = await axios.get<NftsResponse>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/web3/nfts?address=${
        user?.ethAddress ?? ""
      }&chainId=${chainId ?? ""}&filter=${category}`
    );
    return data;
  };

  const { isFetched, data } = useQuery(
    [`${chainId ?? "1"}-${user?.ethAddress ?? ""}-test-${category}}`],
    fetchUserNfts,
    {
      enabled: !!user?.ethAddress && !!chainId,
    }
  );

  return isFetched && !!data?.nfts?.length;
};

export const useUserNfts = (params?: { limit?: number }) => {
  const { user } = useUser();
  const chainId = useChainId();

  const asyncTimeout = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const fetchUserNfts = async (
    p: typeof params & { cursor?: string },
    lastNfts: NFT[]
  ): Promise<NFT[]> => {
    const { data } = await axios.get<NftsResponse>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/web3/nfts?address=${
        user?.ethAddress ?? ""
      }&chainId=${chainId ?? ""}&limit=${p.limit ?? 20}${
        p.cursor ? `&cursor=${p.cursor}` : ""
      }`
    );

    if (!!data.cursor) {
      await asyncTimeout(500);
      return [
        ...(await fetchUserNfts({ ...p, cursor: data.cursor }, [
          ...lastNfts,
          ...data.nfts,
        ])),
      ];
    } else {
      return [...lastNfts, ...data.nfts];
    }
  };

  return useQuery({
    queryKey: [`${chainId ?? "1"}-${user?.ethAddress ?? ""}-all-evm-nfts}`],
    queryFn: async () =>
      await fetchUserNfts({ limit: params?.limit ?? 20 }, []),
    enabled: !!user?.ethAddress && !!chainId,
  });
};

interface FilterOptions {
  tokenAddresses: string[];
  filters: string[];
  cursor?: string;
}

export const useUserNftFilter = (nfts: NFT[]) => {
  const filterNfts = useCallback(
    (options: FilterOptions) => {
      const tokenAddresses = options.tokenAddresses.map((a) => a.toUpperCase());
      const filters = options.filters;
      const currentPage = isNaN(parseInt(options.cursor ?? ""))
        ? 1
        : parseInt(options.cursor ?? "");

      const filteredNfts = nfts
        .filter(
          (nft) =>
            !tokenAddresses.length ||
            tokenAddresses.includes(nft.tokenAddress.toUpperCase())
        )
        .filter(
          (nft) =>
            !filters?.length ||
            filters.reduce(
              (acc, cur) =>
                acc &&
                new RegExp(cur, "i").test(
                  `${nft?.metadata?.["name"] ?? `${nft.tokenId}`}`
                ),
              true
            )
        );

      const totalPage = Math.ceil(filteredNfts.length / 20);

      const paginatedNfts = filteredNfts.slice(
        (currentPage - 1) * 20,
        currentPage * 20
      );

      return {
        hasNext: currentPage < totalPage,
        cursor: `${currentPage + 1}`,
        nfts: paginatedNfts,
      };
    },
    [nfts]
  );

  return filterNfts;
};
