import { atom } from "jotai";
import { type BreadCrumbsType } from "~/type";

export const BLOCKCHAINS = {
  id: "blockchain",
  name: "Blockchain",
  options: [
    { value: "eth", label: "ETHEREUM" },
    { value: "wax", label: "WAX" },
  ],
};

export const blockchainAtom = atom("eth");
blockchainAtom.debugLabel = "blockchainAtom";

export const whitelistedAddress = atom<string[]>([]);
whitelistedAddress.debugLabel = "whitelistedAddress";

export const waxWhitelistedAddress = atom<string[]>([]);
waxWhitelistedAddress.debugLabel = "waxWhitelistedAddress";

export const ethWhitelist = atom((get) => get(whitelistedAddress).join(","));
ethWhitelist.debugLabel = "ethWhitelist";

export const waxWhitelist = atom((get) => get(waxWhitelistedAddress).join(","));
waxWhitelist.debugLabel = "waxWhitelist";

export const breadcrumbsAtom = atom<BreadCrumbsType[]>([]);
