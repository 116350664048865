import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";
import { type AccessMode, type AccessType } from "~/data/useDistrictAccess";
import { env } from "~/env.mjs";
import useUser from "~/lib/useUser";
import { getProductTypeViaUrl } from "~/utils";

export interface IDistrict {
  id?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  socialLinkFacebook?: string;
  socialLinkTwitter?: string;
  socialLinkWebsite?: string;
  accentColor?: string;
  logo?: string;
  banner?: string;
  interior?: string;
  material?: string;
  display?: string;
  accessMode?: AccessMode;
  accessType?: AccessType;
  maxPopulation?: number;
  pin?: string;
  enablePin?: boolean;
  networkSync?: boolean;
  audioUrl?: string;
  audioUrls?: string[];
  deleteAudioUrlIds?: string[];
  enableNPC?: boolean;
  shopId?: string;
}

export const useDistrictData = () => {
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);

  const queryFn = async () => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/districts/${contractAddress}/${tokenId}`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/${productType}/${tokenId}`;

    const { data } = await axios.get<
      IDistrict & { _id: string; token_id: string }
    >(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [productType, contractAddress, tokenId];
  const queryObj = useQuery({
    queryKey,
    queryFn,
    enabled: !!contractAddress && !!tokenId,
  });
  return {
    ...queryObj,
    data: !!queryObj.data
      ? {
          ...queryObj.data,
          // If _id and token_id are the same, then apartment data has not been created correctly, return id: undefined so that apartment data can be created
          id: queryObj.data.id
            ? queryObj.data.id
            : queryObj.data._id === queryObj.data.token_id
            ? undefined
            : queryObj.data._id,
        }
      : undefined,
  };
};

export const useDistrictPin = () => {
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);

  const fetchDistrictPin = async () => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/${productType}/${contractAddress}/${tokenId}/pin`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/${productType}/${tokenId}/pin`;
    const { data } = await axios.get<{ pin?: string }>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [productType, contractAddress, tokenId, "pin"];
  const queryObj = useQuery({
    queryKey,
    queryFn: fetchDistrictPin,
    enabled: !!contractAddress && !!tokenId,
  });
  return queryObj;
};

export const useCreateDistrict = () => {
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const chainId = query.chainId as string;

  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);

  const createDistrict = async (data: IDistrict) => {
    const payload = {
      ...data,
      tokenId,
      chainId,
      contractAddress,
    };
    await axios.post<IDistrict>(
      `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/${productType}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn: createDistrict,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        contractAddress,
        tokenId,
      ]);
    },
  });
};

export const useUpdateDistrictData = () => {
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;

  const queryClient = useQueryClient();
  const { user } = useUser();
  const accessToken = user?.accessToken || "";
  const productType = getProductTypeViaUrl(pathname);

  const updateDistrict = async (data: IDistrict) => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/districts/${contractAddress}/${tokenId}`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/apartment/${tokenId}`;

    await axios.patch<IDistrict>(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationFn: updateDistrict,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        productType,
        contractAddress,
        tokenId,
      ]);
    },
  });
};

export const useCheckDistrictPin = () => {
  const { query, pathname } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const productType = getProductTypeViaUrl(pathname);

  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const checkDistrictPin = async (pin: string) => {
    const url =
      productType === "districts"
        ? `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/${productType}/${contractAddress}/${tokenId}/pin/check`
        : `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/${productType}/${tokenId}/pin/check`;
    return axios.post<boolean>(
      url,
      {
        pin,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationFn: checkDistrictPin,
  });
};

interface ISubmittedAssets {
  id: string;
  assetName: string;
  assetCategory: string;
  assetDescription: string;
  assetUrl: string;
  assetThumbnailUrl: string;
  submissionEmail: string;
  submissionDiscordTag: string;
  userId: string;
  feedback: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  revision: number;
  deleted: boolean;
  deletedAt: string | null;
}
export const useSubmittedAssets = () => {
  const { query } = useRouter();
  const contractAddress = query.contractAddress as string;
  const tokenId = query.tokenId as string;
  const { user } = useUser();
  const accessToken = user?.accessToken || "";

  const queryFn = async () => {
    const url = `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/creator-tools/submission-approved`;

    const { data } = await axios.get<{ submissions: ISubmittedAssets[] }>(url, {
      params: {
        page: 1,
        limit: 10,
        orderBy: "desc",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  };

  const queryKey = [user?.id, "submission-approved"];
  const queryObj = useQuery({
    queryKey,
    queryFn,
    enabled: !!contractAddress && !!tokenId && !!user?.id && !!accessToken,
  });
  return {
    ...queryObj,
  };
};
